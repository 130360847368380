import React, { useCallback, useEffect, useMemo, useState } from "react";
import { juhuu } from "../../juhuuClass";
import { JUHUU, LanguageCode } from "@juhuu/sdk-ts";
import { useLocation, useParams } from "react-router-dom";
import { Strong, Text } from "../../components/text";
import Markdown from "../../components/markdown";
import Spinner from "../../components/spinner";
import { Heading, Subheading } from "../../components/heading";
import { useLanguage } from "../../context/LanguageContext";
import LanguageCodeee from "../../context/LanguageCodeee";
import AlertBox from "../../components/alert-box";
import { Button } from "../../components/button";

interface ArticleRouteProps {}

const ArticleRoute: React.FC<ArticleRouteProps> = () => {
  const [article, setArticle] = useState<JUHUU.Article.Object | null>(null);
  const { articleId } = useParams<{ articleId: string }>();
  const location = useLocation();
  const [notFoundError, setNotFoundError] = useState(false);
  const [anotherError, setAnotherError] = useState(false);
  const [property, setProperty] = useState<JUHUU.Property.Object | null>(null);
  const { currentLanguageCode, setLanguage } = useLanguage();

  const articleExistsInCurrentLanguage = useMemo(() => {
    if (article === null) {
      return false;
    }

    return article.title?.[currentLanguageCode] !== undefined;
  }, [article, currentLanguageCode]);

  const availableLanguageCodeArray: LanguageCode[] =
    useMemo((): LanguageCode[] => {
      if (article === null) {
        return [];
      }

      return Object.keys(article.title ?? {}) as LanguageCode[];
    }, [article]);

  useEffect(() => {
    if (article?.propertyId === undefined) {
      return;
    }

    juhuu.properties
      .retrieve({
        propertyId: article.propertyId,
      })
      .then((query) => {
        if (query.ok === false) {
          return;
        }

        setProperty(query.data.property);
      });
  }, [article?.propertyId]);

  const handleRefresh = useCallback(async () => {
    setNotFoundError(false);
    setAnotherError(false);
    if (articleId === undefined) {
      const query = await juhuu.articles.search({
        slug: location.pathname,
      });

      if (query.ok === false) {
        setAnotherError(true);
        return;
      }

      if (query.data.articleArray.length === 0) {
        setNotFoundError(true);
        return;
      } else if (query.data.articleArray.length > 1) {
        setNotFoundError(true);
        return;
      }

      setArticle(query.data.articleArray[0]);
    } else {
      const query = await juhuu.articles.retrieve({
        articleId: articleId,
      });

      if (query.ok === false) {
        setAnotherError(true);
        return;
      }

      setArticle(query.data.article);
    }
  }, [articleId, location]);

  useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  useEffect(() => {
    if (article === null) {
      return;
    }
  }, [article]);

  const handleTranslate = useCallback(async () => {
    if (article === null) {
      return;
    }

    const query = await juhuu.articles.translate({
      articleId: article.id,
      languageCode: currentLanguageCode,
    });

    if (query.ok === false) {
      return;
    }

    setArticle(query.data.article);
  }, [article, currentLanguageCode]);

  return (
    <>
      {article !== null &&
        article.languageCodeArray.includes(currentLanguageCode) === false &&
        availableLanguageCodeArray.length > 0 &&
        articleExistsInCurrentLanguage === true && (
          <>
            <AlertBox color="amber">
              The article you are reading was not originally published in{" "}
              <LanguageCodeee languageCode={currentLanguageCode} />. It was
              translated by AI and might contain errors in the translation. We
              do not take any responsibility for the correctness of the
              translation.
            </AlertBox>
          </>
        )}
      {article === null ? (
        <div className="flex flex-col justify-start items-center w-full">
          {notFoundError === true ? (
            <>
              <Text>The article could not be found.</Text>
              <Text>
                This happened, because its url changed, it was deleted, or it
                never existed in the first place.
              </Text>
            </>
          ) : (
            <>
              {anotherError === true ? (
                <Text>
                  The article could not be retrieve, please try to reload the
                  page.
                </Text>
              ) : (
                <Spinner />
              )}
            </>
          )}
        </div>
      ) : (
        <>
          {articleExistsInCurrentLanguage === false ? (
            <div className="flex flex-col justify-start items-center w-full">
              <Text>
                This article is not yet available in{" "}
                <LanguageCodeee languageCode={currentLanguageCode} />.
              </Text>
              <Text className="mb-4">
                Please select one of the following languages to read the
                article:
              </Text>
              {availableLanguageCodeArray.map((languageCode) => {
                return (
                  <Text
                    key={languageCode}
                    className="text-lg rounded-full cursor-pointer py-1"
                    onClick={() => {
                      setLanguage(languageCode);
                    }}
                  >
                    <Strong>
                      <LanguageCodeee languageCode={languageCode} />
                    </Strong>
                  </Text>
                );
              })}
              {article.autoTranslateEnabled === true && (
                <>
                  <Button onClick={() => handleTranslate()} className="mt-4">
                    Translate to{" "}
                    <LanguageCodeee languageCode={currentLanguageCode} /> with
                    AI
                  </Button>
                </>
              )}
            </div>
          ) : (
            <>
              <Heading className="text-center mb-8">
                {article.title?.[currentLanguageCode]}
              </Heading>
              <Markdown
                markdown={
                  article?.markdownContent !== null
                    ? article?.markdownContent[currentLanguageCode]
                    : null
                }
              />
              {property !== null && property.type === "internal" && (
                <>
                  <Subheading className="mt-32">
                    Author of this article
                  </Subheading>
                  <Text>{property.name}</Text>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default ArticleRoute;
