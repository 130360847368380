import React from "react";
import { TextLink } from "../../components/text";

const Footer: React.FC = () => {
  return (
    <footer className="mt-44">
      <div className="max-w-6xl mx-auto px-4 sm:px-0">
        <p className="text-sm dark:text-white">
          © {new Date().getFullYear()} JUHUU BikeBox GmbH.{" "}
          <TextLink href="https://docs.juhuu.app/legal/impressum">
            Imprint
          </TextLink>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
