import {
  Sidebar,
  SidebarBody,
  SidebarItem,
  SidebarSection,
} from "./components/sidebar";
// import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { StackedLayout } from "./components/stacked-layout";
import { Navbar, NavbarItem, NavbarSection } from "./components/navbar";
import {
  LanguageIcon,
  LifebuoyIcon,
  MoonIcon,
  SunIcon,
} from "@heroicons/react/16/solid";
import { Switch, SwitchField } from "./components/switch";
import { Label } from "./components/fieldset";
import { useLanguage } from "./context/LanguageContext";
import LanguageCodeee from "./context/LanguageCodeee";
import Footer from "./routes/footer/Footer";

interface PageProps {
  children: React.ReactNode;
}

const Page: React.FC<PageProps> = ({ children }) => {
  // const location = useLocation();
  const { openLanguageSelector, currentLanguageCode } = useLanguage();
  const [colorScheme, setColorScheme] = useState<"dark" | "light">("light");

  const handleToggleColorScheme = () => {
    console.log("toggle theme");
    if (colorScheme === "dark") {
      localStorage.setItem("theme", "light");
      setColorScheme("light");
    } else {
      localStorage.setItem("theme", "dark");
      setColorScheme("dark");
    }
  };

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme === "dark") {
      setColorScheme("dark");
    } else {
      setColorScheme("light");
    }
  }, []);

  // const navItems = [
  //   { label: "Home", url: "" },
  //   { label: "Academy", url: "academy" },
  //   { label: "Engineering", url: "engineering" },
  //   { label: "API", url: "api" },
  // ];

  return (
    <div
      className={colorScheme}
      style={{
        overflowX: "hidden",
        minHeight: "100vh", // Ensure full-page height
        position: "relative",
      }}
    >
      <StackedLayout
        navbar={
          <Navbar className="mx-auto max-w-6xl px-4 sm:px-0">
            <NavbarSection className="max-lg:hidden">
              {/* {navItems.map(({ label, url }) => (
                <NavbarItem
                  key={label}
                  href={"/" + url}
                  current={location.pathname.split("/")[1] === url}
                >
                  {label}
                </NavbarItem>
              ))} */}
              <NavbarItem onClick={() => openLanguageSelector()}>
                <LanguageIcon />
                <LanguageCodeee languageCode={currentLanguageCode} />
              </NavbarItem>
              <NavbarItem onClick={() => handleToggleColorScheme()}>
                {colorScheme === "dark" ? (
                  <>
                    <MoonIcon /> Darkmode
                  </>
                ) : (
                  <>
                    <SunIcon /> Lightmode
                  </>
                )}
              </NavbarItem>
              <NavbarItem
                onClick={() =>
                  window.open("https://juhuu.app/contact", "_blank")
                }
              >
                <LifebuoyIcon />
                Contact us
              </NavbarItem>
            </NavbarSection>
          </Navbar>
        }
        sidebar={
          <Sidebar>
            <SidebarBody>
              <SidebarSection>
                {/* {navItems.map(({ label, url }) => (
                  <SidebarItem
                    key={label}
                    href={url}
                    current={location.pathname.split("/")[1] === url}
                  >
                    {label}
                  </SidebarItem>
                ))} */}
                <SidebarItem>
                  {colorScheme === "dark" ? <MoonIcon /> : <SunIcon />}
                  <SwitchField>
                    <Label>Darkmode</Label>
                    <Switch
                      onChange={handleToggleColorScheme}
                      checked={colorScheme === "dark"}
                    />
                  </SwitchField>
                </SidebarItem>
              </SidebarSection>
            </SidebarBody>
          </Sidebar>
        }
      >
        {children}
        <Footer />
      </StackedLayout>
    </div>
  );
};

export default Page;
